@import "index";

.cLnjxE {
	margin: 0 !important;
	height: 10px !important;
}

.euyVNb {
	// -webkit-box-pack: center !important;
 //    -webkit-justify-content: center !important;
 //    -ms-flex-pack: center !important;
 //    justify-content: center !important;
}

.block {
	border: 1px solid lightgrey; 
	height: 10px;
	&:hover {
		cursor: pointer;
	}
	// width: 50px;
}

.loading {
	margin: 0 auto;
}

.Schedule .block {
	// border: 0px solid lightgrey; 
}

.week .block{
	height: 160px;
	width: 50px;
}

.week .fByCIL{
	display: none;
}

.selected {
	// background:  #3b3b3b;
	background: $highlight-color;
}

button.options {
	text-align: left;
	padding: 10px;
	background: white;
	// border: 1px solid rgba(59, 59, 59, 0.3);
	border: 1px solid $highlight-color;
	// color: rgb(59, 59, 59);
	color: $highlight-color;
	&:hover {
		// background: rgba(59, 59, 59, 0.8);
		// border: rgba(59, 59, 59, 0.8);
		// color: white;
		cursor: pointer;
	}
}

button.selected {
	color: white;
	// background: rgba(59, 59, 59, 1);
	background: $highlight-color;
	// border: 1px solid rgba(59, 59, 59, 1);
	border: 1px solid $highlight-color;
}

.week .cPSTdm {
	flex-grow: 0 !important;
	-webkit-flex-grow: 0 !important;
}

.week .cSbxjh, .dates .cSbxjh {
    flex-wrap: wrap;
    margin: 0 auto;
	justify-content: center;
}

.cSbxjh .month{
	flex-wrap: wrap;
}

.dates .fByCIL{
	display: none;
}

.dates .sc-bdVaJa.sc-gzVnrw.hfiTtC {
	display: none;
}

.dates .block {
	outline-width: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 50px;
	margin-bottom: 1px;
	// background: #f4f4f4;
}

.dates .block.selected {
	// background: #3b3b3b;
	background: $highlight-color;
	color: white;
}

.week .block {
	// background: #f4f4f4;
	outline-width: 0px;
	margin: 0 0.5px;
}

.week .block.selected {
	// background: #3b3b3b;
	background: $highlight-color;
	color: white;
}

.DateColumn {
	padding: 0 10px;
}

.Schedule div:not(.rgdp__grid-cell).sc-EHOje.lcPmgN {
	&:first-child {
		min-width: 50px;
		border-bottom: 1px solid lightgrey;
		// border-bottom: 1px solid $highlight-color;
	}
}

.Schedule .sc-bxivhb.cSbxjh div:nth-last-child(2) .block{
	border-right: 1px solid lightgrey !important;
	// border-right: 1px solid $highlight-color !important;
}

.Half {
	display: inline-block; 
	width: 100%;
	vertical-align: top;
	min-width: 300px;
	max-width: 500px; 
	text-align: center;
    padding: 0 20px;
}

@media only screen and (max-width: 799px) {
	.Half.hidden {
		display: none;
	}
}

@media only screen and (max-width: 1099px) {
	.Half.dmhidden {
		display: none;
	}
}

.TimeSelect {
	width: auto;
	height: 30px;
	border: none;
	padding: 5px;
	// background: #3b3b3b;
	background: $highlight-color;
	color: white;
	text-align: center;
	border-radius: 5px;
}

button.typeSelector {
	// background: #3b3b3b;
	background: $highlight-color;
	width: 50%;
}

button.typeSelector.selected{
	// border: 1px solid #3b3b3b;
	border: 1px solid $highlight-color;
	// color: #3b3b3b;
	color: $highlight-color;
	background: white;
	width: 50%;
}

button.monthButton {
	color: black;
	background: none;
	// display: flex;
	width: 30px;
	height: 20px;
	border-width: 0;
}

button.submit{
	width: 100%;
}