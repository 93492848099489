body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// zoom: 0.8;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

// $highlight-color: #3b3b3b;
$highlight-color: #7d82b8;
$text-color: rgba(0, 0, 0, 0.85);
$light-color: grey;

div,
p,
h1,
h2,
h3,
h4 {
	color: $text-color;
}

div,
p,
a {
	font-family: "Open Sans";
	font-weight: 400;
}

h1,
h2,
h3,
h4 {
	font-family: "Open Sans";
	font-weight: 600;
}

h3 {
	color: $highlight-color;
}

a:hover {
	// color: $text-color;
	text-decoration: none;
}

button {
	border-radius: 10px;
	background: $highlight-color;
	color: white;
	width: 100%;
	height: 50px;
	border-width: 0px;
	&:hover {
		// background: $highlight-color;
		// color: white;
	}
	&:focus {
		outline: 0;
	}
}

label {
	margin-bottom: 0rem;
}

input,
textarea {
	padding: 10px;
	height: 50px;
	border: 1px solid rgba(59, 59, 59, 0.3);
	background: none;
	width: 100%;
	border-radius: 10px;
}

input[type="date"]:before {
	content: attr(placeholder) !important;
	color: rgba(59, 59, 59, 0.7);
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
	content: "";
}

input[type="month"]:before {
	content: attr(placeholder) !important;
	color: #6e789e;
}

input[type="month"]:focus:before,
input[type="month"]:valid:before {
	content: "";
}

input:focus,
textarea:focus {
	outline: none;
}
